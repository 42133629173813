<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-07-19 09:16:23
-->
// 服务合同管理

<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="goHome()" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span class="cursor">
          服务合同管理
        </span>
      </template>
      <template v-slot:slotCon>
        <div class="boxCon base_333_color">
          <div class="small">
            <div @click="go(0)" class="cursor">
              <img src="../image/zhongdian.png" alt="" />
              <span>钟点服务</span>
            </div>
            <div class="cursor" @click="go(1)">
              <img src="../image/duanqi.png" alt="" />
              <span>短期应急 ( 医院陪护 )</span>
            </div>
          </div>
          <div class="small">
            <div class="cursor" @click="go(2)">
              <img src="../image/shenghuo.png" alt="" />
              <span>24小时生活照护</span>
            </div>
            <div class="cursor" @click="go(3)">
              <img src="../image/wuzhangai.png" alt="" />
              <span>适老无障碍设施改造</span>
            </div>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <!-- <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template> -->
      <template #one>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽

export default {
  data() {
    return {
      name: "",
      type_num: "",
    };
  },
  components: { TitleNav, ebutton },
  created() {},
  methods: {
    goHome() {
      this.$router.push("/home");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    go(key) {
      this.type_num = key;
      this.$store.commit("setTypeNum", this.type_num);
      this.$router.push("/serveDetail");
    },
    // goContract() {
    //   this.$router.push("/serveManage");
    // },
  },
};
</script>

<style scoped>
.boxCon {
  text-align: center;
}
.small {
  display: inline-block;
}
.small > div {
  width: 388px;
  height: 270px;
  background-color: #fff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  margin: 24px 13px;
  /* line-height: 270px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.small > div img {
  width: 100px;
  height: 100px;
}
.small > div span {
  margin-top: 30px;
  font-size: 24px;
}
/* ---------------------------------------------------- */
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
</style>
